<template>
  <a class="page-reports-download-link" :href="href" v-text="$tf('download')" download></a>
</template>

<script>
export default {
  name: 'PageReportsDownloadLink',
  props: {
    href: { type: String, required: true }
  }
};
</script>

<style>
.page-reports-download-link {
  white-space: nowrap;
}
</style>
