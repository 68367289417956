<template>
  <div class="page-reports-status">
    <em class="page-reports-status__text" :class="className" v-text="$tf(status)" />
    <slot v-if="isDownloadLinkVisible" name="download-link" />
  </div>
</template>

<script>
import { ReportStatus } from '@/store/reports/enums.reports';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'PageReportsStatus',
  props: {
    status: { type: String, required: true }
  }
})
export default class extends Vue {
  get className() {
    return 'page-reports-status__text_' + this.status;
  }

  get isDownloadLinkVisible() {
    return this.status === ReportStatus.COMPLETED;
  }
}
</script>

<style lang="stylus">
.page-reports-status {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &__text {
    flex: 1;
    font-style: normal;
    white-space: nowrap;
    text-transform: capitalize;

    &_error {
      color: #c00;
    }

    &_completed {
      color: #0c0;
    }

    &_processing {
      color: #fc0;

      &::after {
        content: '';
        animation: processing 2s linear infinite;
      }
    }
  }
}

@keyframes processing {
  25% {
    content: '.';
  }

  50% {
    content: '..';
  }

  75% {
    content: '...';
  }
}
</style>
