<template>
  <el-table ref="table" :data="items" :size="tableSize" v-loading="loading" :default-sort="defaultSort" @selection-change="dispatchSelectionChange" stripe>
    <el-table-column type="selection" />
    <el-table-column prop="id" :label="$tf('id')" width="68" sortable />
    <el-table-column prop="name" :label="$tf('name')" min-width="240" />
    <el-table-column prop="type" :label="$tf('type')" :formatter="formatType" />
    <el-table-column prop="modified_date" :label="$tf('modified')" :sort-method="compareModifiedDates" :formatter="formatModifiedDate" sortable />
    <el-table-column prop="records_number" :label="$tf('records')" />
    <el-table-column prop="size" :label="$tf('size')" :formatter="formatSize" sortable />
    <el-table-column prop="status" :label="$tf('status')">
      <page-reports-status slot-scope="{ row }" :status="row.status">
        <page-reports-download-link slot="download-link" :href="row.report_file" />
      </page-reports-status>
    </el-table-column>
  </el-table>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import { bytesToKb, formatDateTime } from '@/apps/common/filters';
import PageReportsDownloadLink from './page-reports-download-link';
import PageReportsStatus from './page-reports-status';

@Component({
  name: 'PageReportsTable',
  props: {
    items: { type: Array, required: true },
    loading: { type: Boolean, default: false }
  },
  components: { PageReportsDownloadLink, PageReportsStatus }
})
export default class extends Vue {
  defaultSort = { prop: 'id', order: 'descending' };

  get tableSize() {
    const { sizes } = this.$vars;
    return sizes.table || sizes.common;
  }

  get datesMap() {
    return this.items.reduce(appendModifiedDateToMap, {});
  }

  compareModifiedDates(reportA, reportB) {
    return this.datesMap[reportA.id] - this.datesMap[reportB.id];
  }

  formatModifiedDate({ id }) {
    return formatDateTime(this.datesMap[id]);
  }

  formatSize({ size }) {
    return size && bytesToKb(size);
  }

  formatType({ type }) {
    return this.$tf(type);
  }

  dispatchSelectionChange(selection) {
    this.$emit('selection-change', selection);
  }
}

function appendModifiedDateToMap(datesMap, { id, modified_date }) {
  datesMap[id] = Date.parse(modified_date);
  return datesMap;
}
</script>
